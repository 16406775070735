<template>
  <div class="info-container">
    <div class="sub-navigation-container column">
      <a v-for="(item, index) in nav[0].subNav"
      :key="'sub-navigation-'+index"
      :href="(item.link? item.link : item.outerLink)">
      <p >{{item.title}}</p>
      </a>
    </div>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../../components/common/Footer';
import { nav } from '../../data/nav'

export default {
  components : {
    Footer
  },

  data() {
    return {
      nav,
    }
  }
}
</script>

<style>

</style>
